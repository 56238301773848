import './uploads'
import { Stimulus, Controller } from '#/layouts/js/stimulus'

Stimulus.register('category', class extends Controller {
  connect() {
    this.change()
  }

  change() {
    const el = document.getElementById('performing-arts')

    if (this.element.value == 'Performing Arts') {
      el.classList.remove('d-none')
    } else {
      el.classList.add('d-none')
    }
  }
})

Stimulus.register('uploads', class extends Controller {
  remove() {
    this.element.closest('.card').remove()
  }
})

Stimulus.register('last-entry', class extends Controller {
  copy() {
    const json = JSON.parse(document.querySelector('#last-entry span.d-none').innerText)
    Object.keys(json).forEach((key) => {
      const input = document.getElementById(`entry_${key}`)
      if (key == 'address') {
        input.textContent = json[key]
      } else {
        input.value = json[key]
      }
    })
  }
})

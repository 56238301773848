import ujs from '@rails/ujs'
ujs.start()

import * as ActiveStorage from '@rails/activestorage'
ActiveStorage.start()

import 'lite-youtube-embed'
import 'lite-youtube-embed/src/lite-yt-embed.css'

import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.css'

import '#/layouts/js'
import '#/entries/js'
import '#/admin/entries/js'
import '#/admin/pages/css'

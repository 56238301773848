document.addEventListener('direct-upload:initialize', e => {
  const { id, file } = e.detail

  document.getElementById('entry_uploads').classList.add('d-none')
  document.getElementById('uploads-progress').insertAdjacentHTML('beforebegin', `
    <div id="direct-upload-${id}" class="card">
      <div class="card-body">
        <p>${file.name}</p>
        <div class="progress" role="progressbar">
          <div class="progress-bar progress-bar-striped progress-bar-animated" style="width: 100%"></div>
        </div>
      </div>
    </div>
  `)
})

document.addEventListener('direct-upload:start', e => {
  const el = document.querySelector(`#direct-upload-${e.detail.id} .progress-bar`)

  el.classList.remove('progress-bar-striped', 'progress-bar-animated')
  el.style.width = '0%'
  el.textContent = '0%'
})

document.addEventListener('direct-upload:progress', e => {
  const { id, progress } = e.detail
  const el = document.querySelector(`#direct-upload-${id} .progress-bar`)

  el.style.width = `${progress}%`
  el.textContent = `${Math.round(progress)}%`
})

document.addEventListener('direct-upload:error', e => {
  e.preventDefault()
  const { id, error } = e.detail
  const el = document.querySelector(`#direct-upload-${id} .progress`)

  el.querySelector('.progress-bar').classList.add('bg-danger')
  el.insertAdjacentHTML('afterend', `
    <p class="text-danger">${error}</p>
  `)
})

document.addEventListener('direct-upload:end', e => {
  document.querySelector(`#direct-upload-${e.detail.id} .progress-bar`).classList.add('bg-success')
})
